<template>
  <div class="programme py-5">
    <div class="programme-text text-center py-4">
      <h2>Programme</h2>
    </div>
    <div class="content py-5" :class="{ 'mobile-content': type != 'desktop' }">
      <div class="container-xl">
        <div class="accordion" id="accordionExample">
          <div class="card mb-5 part-1 rounded">
            <div class="card-header" id="headingOne">
              <h2 class="mb-0">
                <button
                  class="btn btn-block btn-header-link text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <div class="row">
                    <div class="col-sm-3 col-5 py-3">
                      <div class="prog-part py-4">AM (PART 1)</div>
                    </div>
                    <div class="col-sm-9 col-7 py-3">
                      <div class="prog-title py-4 rounded-right px-4">
                        Opening of CHi innovate 2023
                      </div>
                    </div>
                    <div class="col-sm-3 col-5 py-2"></div>
                    <div class="col-sm-9 col-7 py-2">
                      <div class="prog-detail pl-4">
                        Opening Address by Guest of Honour<br />
                        National Healthcare Innovation and Productivity (HIP)
                        Medals Award Ceremony
                      </div>
                    </div>
                  </div>
                </button>
              </h2>
            </div>

            <div
              id="collapseOne"
              class="collapse"
              aria-labelledby="headingOne"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-3 col-5 py-3 prog-head">TIME</div>
                  <div class="col-sm-9 col-7 py-3 prog-head">PROGRAMME</div>
                  <div class="col-sm-3 col-5 py-3 prog-time">
                    8.30am - 8.40am (SGT)
                  </div>
                  <div class="col-sm-9 col-7 py-3 prog-detail-content">
                    <div class="title">Opening of CHI INNOVATE 2023</div>

                    <div class="detail py-2">
                      <div class="mb-3">
                        Healthcare is expanding our focus: from healthcare to
                        health; from patients to people; from clinical centres
                        to communities of care.
                      </div>
                      <div>
                        CHI INNOVATE 2023 will galvanise us to breach
                        traditional boundaries and work with community partners
                        to catalyse health and social change, ultimately
                        creating more sustainable healthcare and healthy
                        communities.
                      </div>
                    </div>

                    <div class="speaker py-4">
                      <div class="desg font-weight-bold">
                        <i>Co-Hosted by </i>
                      </div>

                      <div
                        class="name pointer"
                        @click="openModal('/speakers/desktop/co host/popup/1')"
                      >
                        A/Prof Wong Hon Tym
                      </div>
                      <div class="desg">Clinical Director</div>
                      <div class="location">
                        Centre for Healthcare Innovation
                      </div>
                    </div>

                    <div class="speaker py-4">
                      <div
                        class="name pointer"
                        @click="openModal('/speakers/desktop/co host/popup/2')"
                      >
                        Tamsin Greulich-Smith
                      </div>
                      <div class="desg">Director, School of X</div>
                      <div class="location">DesignSingapore Council</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-5 part-2 rounded">
            <div class="card-header" id="headingTwo">
              <h2 class="mb-0">
                <button
                  class="btn btn-block btn-header-link text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <div class="row">
                    <div class="col-sm-3 col-5 py-3">
                      <div class="prog-part py-4">AM (PART 2)</div>
                    </div>
                    <div class="col-sm-9 col-7 py-3">
                      <div class="prog-title py-4 rounded-right px-4">
                        Who Needs to Build a Bridge? 
                      </div>
                    </div>
                    <div class="col-sm-3 col-5 py-2"></div>
                    <div class="col-sm-9 col-7 py-2">
                      <div class="prog-detail pl-4 mb-3">
                        Do healthcare & social care understand each other well
                        enough to work together? How do we get healthcare &
                        social care to ‘Swipe Right’ on each other?
                      </div>
                      <div class="prog-detail pl-4">
                        As we enter a new age of healthcare in Singapore, CHI
                        INNOVATE kick starts our annual conference with engaging
                        conversation starters – in the form of a 5-persona panel
                        - set to help you understand, connect and build bridges
                        between the health and social sector!
                      </div>
                    </div>
                  </div>
                </button>
              </h2>
            </div>
            <div
              id="collapseTwo"
              class="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-3 col-5 py-3 prog-head">TIME</div>
                  <div class="col-sm-9 col-7 py-3 prog-head">PROGRAMME</div>
                  <div class="col-sm-3 col-5 py-3 prog-time">
                    8.40am - 9.05am (SGT)
                  </div>
                  <div class="col-sm-9 col-7 py-3 prog-detail-content">
                    <div class="speaker pb-4">
                      <div class="name">CentreStage Speaker</div>
                      <div class="detail my-3">
                        <div class="title">A Jaywalker’s Journey</div>
                        <div>
                          Innovation requires bridges. But what happens before
                          bridges are built? This is the landscape the Lien
                          Foundation operates in when developing programmes. Poh
                          Wah will share what it’s like to dodge the traffic of
                          conformity, design different paths, and discover new
                          possibilities with a jaywalker’s fortitude, agility –
                          and idiocy.
                        </div>
                      </div>
                      <div
                        class="name pointer"
                        @click="
                          openModal(
                            '/speakers/desktop/CentreStage Speakers/popup/1'
                          )
                        "
                      >
                        Lee Poh Wah
                      </div>
                      <div class="desg">CEO</div>
                      <div class="location">Lien Foundation</div>
                    </div>
                  </div>

                  <div class="col-sm-3 col-5 py-3 prog-time">
                    9.05am - 10.00am (SGT)
                  </div>
                  <div class="col-sm-9 col-7 py-3 prog-detail-content">
                    <!-- <div class="stage mb-1">CENTRESTAGE 1</div> -->
                    <div class="title">
                      5-Persona Panel from the Social Sector
                    </div>
                    <div class="detail py-2">
                      <div class="mb-3">
                        With HealthierSG being the greater national agenda that
                        will set to integrate the two sectors further (health
                        and social care), we want you to join us in this
                        conversation starter, getting to know the 5 key personas
                        from the world of social care: ‘children, youth &
                        family’; ‘disability’; ‘mental health’; ‘caregiver’ and
                        ‘elder care’.
                      </div>
                      <div>
                        Hear from our panellists: their stories, challenges and
                        wish-list as we set to foster better understanding with
                        one another, to build and strengthen the bridge between
                        health and social care.
                      </div>
                    </div>

                    <div class="speaker py-4">
                      <div class="desg font-weight-bold">
                        <i>Moderated by </i>
                      </div>
                      <div
                        class="name pointer"
                        @click="
                          openModal('/speakers/desktop/panel moderator/popup/1')
                        "
                      >
                        Dr Douglas O'Loughlin
                      </div>
                      <div class="desg">
                        Principal <br />

                        The Dao of Thriving <br />

                        Associate Consultant
                      </div>
                      <div class="location">Civil Service College</div>
                    </div>

                    <div class="panellists">
                      <div class="title">Panellists</div>
                      <div class="row">
                        <div class="col-sm-4">
                          <div
                            class="speaker py-4 pointer"
                            @click="
                              openModal('/speakers/desktop/panellists/popup/3')
                            "
                          >
                            <div class="name">Nancy Ng</div>
                            <div class="desg">
                              Director, Family Support Division, Family Life
                              Group
                            </div>
                            <div class="location">
                              Ministry of Social and Family Development
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="speaker py-4">
                            <div
                              class="name pointer"
                              @click="
                                openModal(
                                  '/speakers/desktop/panellists/popup/1'
                                )
                              "
                            >
                              Koh Seng Choon
                            </div>
                            <div class="desg">Executive Director & Founder</div>
                            <div class="location">
                              Project Dignity (Singapore & Hong Kong)
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="speaker py-4">
                            <div
                              class="name pointer"
                              @click="
                                openModal(
                                  '/speakers/desktop/panellists/popup/2'
                                )
                              "
                            >
                              Porsche Poh
                            </div>
                            <div class="desg">Executive Director</div>
                            <div class="location">Silver Ribbon</div>
                          </div>
                        </div>

                        <div class="col-sm-4">
                          <div class="speaker py-4">
                            <div
                              class="name pointer"
                              @click="
                                openModal(
                                  '/speakers/desktop/panellists/popup/4'
                                )
                              "
                            >
                              Josie Liow
                            </div>
                            <div class="desg">Co-Chair</div>
                            <div class="location">
                              SingHealth Patient Advocacy Network
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="speaker py-4">
                            <div
                              class="name pointer"
                              @click="
                                openModal(
                                  '/speakers/desktop/panellists/popup/5'
                                )
                              "
                            >
                              Samuel Ng
                            </div>
                            <div class="desg">Founder & CEO</div>
                            <div class="location">Montfort Care</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-3 col-5 py-3 prog-time">
                    10.00am - 10.30am (SGT)
                  </div>
                  <div class="col-sm-9 col-7 py-3 prog-detail-content">
                    <div class="title">Morning Refreshment</div>
                  </div>

                  <div class="col-sm-3 col-5 py-3 prog-time">
                    10.30am - 10.40am (SGT)
                  </div>
                  <div class="col-sm-9 col-7 py-3 prog-detail-content">
                    <div class="title">Opening Address by Guest-of-Honour</div>

                    <div class="speaker py-4">
                      <div class="name">Guest of Honour</div>

                      <div class="name">Ong Ye Kung</div>
                      <div class="desg">Minister of Health</div>
                    </div>
                  </div>

                  <div class="col-sm-3 col-5 py-3 prog-time">
                    10.40am - 11.05am (SGT)
                  </div>
                  <div class="col-sm-9 col-7 py-3 prog-detail-content">
                    <div class="title">
                      National Healthcare Innovation & Productivity (HIP) Medals
                      Award Ceremony
                    </div>

                    <div class="speaker py-4">
                      <div class="desg font-weight-bold">
                        <i>Presented by </i>
                      </div>

                      <div class="name">Ong Ye Kung</div>
                      <div class="desg">Minister of Health</div>
                    </div>
                  </div>

                  <div class="col-sm-3 col-5 py-3 prog-time">
                    11.05am - 11.25am (SGT)
                  </div>
                  <div class="col-sm-9 col-7 py-3 prog-detail-content">
                    <div class="speaker">
                      <div class="name">CentreStage Speaker</div>
                      <div class="detail my-3">
                        <div class="title">
                          Organising & Mobilising for Positive Change
                        </div>
                        <div>
                          Renowned Johns Hopkins University political scientist
                          Prof Han will share research about how to engage
                          people around a common cause. What have history and
                          data taught us about the success and failure of social
                          movements? How do we inspire and galvanize whole
                          communities to transform?
                        </div>
                      </div>

                      <div
                        class="name pointer"
                        @click="
                          openModal(
                            '/speakers/desktop/CentreStage Speakers/popup/2'
                          )
                        "
                      >
                        Prof Hahrie Han
                      </div>
                      <div class="desg">
                        Professor and Director,<br />
                        Stavros Niarchos Foundation Agora Institute
                      </div>
                      <div class="location">Johns Hopkins University</div>
                    </div>
                  </div>

                  <div class="col-sm-3 col-5 py-3 prog-time">
                    11.25am - 11.45am (SGT)
                  </div>
                  <div class="col-sm-9 col-7 py-3 prog-detail-content">
                    <div class="title">Fireside Chat with Prof Hahrie Han</div>
                    <div class="speaker">
                      <div class="desg font-weight-bold">Moderated by</div>
                      <div
                        class="name pointer"
                        @click="
                          openModal('/speakers/desktop/chat moderator/popup/2')
                        "
                      >
                        J R Karthikeyan (Karthik)
                      </div>
                      <div class="desg">CEO</div>
                      <div class="location">AWWA Ltd</div>
                    </div>
                  </div>

                  <div class="col-sm-3 col-5 py-3 prog-time">
                    11.45am - 1.00pm (SGT)
                  </div>
                  <div class="col-sm-9 col-7 py-3 prog-detail-content">
                    <div class="title">Lunch</div>
                  </div>

                  <div class="col-sm-3 col-5 py-3 prog-time">
                    12.00pm – 12.45pm (SGT)
                  </div>
                  <div class="col-sm-9 col-7 py-3 prog-detail-content">
                    <div class="title">Lunch Panel hosted by Intellect</div>
                    <div class="location">Level 2, Hall</div>
                    <div class="title py-3">
                      The Impact of Accessible Mental Health Support on
                      Patients' Health Outcomes
                    </div>
                    <div class="detail">
                      <div class="mb-3">
                        The importance of general mental wellbeing is widely
                        acknowledged. There has been an increasing number of
                        studies that prove correlation between positive mental
                        health and one's overall wellbeing. Taking a step back,
                        the ability to provide accessible mental health support
                        in itself is still a relatively nascent agenda
                        especially in Asia. Nevertheless, every patient can and
                        should benefit from a holistic treatment plan.
                      </div>
                      <div>
                        In this panel, we will explore what accessible mental
                        health support means, and how accessible mental health
                        support benefits patients in achieving long-term and
                        sustained recovery. We will dive into actual use cases,
                        and discuss how we can unlock the potential of this
                        support for patients. Finally, we attempt to answer how
                        an ideal holistic treatment plan should look like, from
                        the lens of the provider, as well as the patient.
                      </div>
                    </div>

                    <div class="speaker py-4">
                      <div class="desg font-weight-bold">
                        <i>Moderated by </i>
                      </div>
                      <div class="name">Cassandra Loh</div>
                      <div class="desg">Head of Strategic Partnerships</div>
                      <div class="location">Intellect</div>
                    </div>

                    <div class="panellists">
                      <div class="title">Panellists</div>
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="speaker py-3">
                            <div class="name">Dr Oliver Suendermann</div>
                            <div class="desg">Clinical Director</div>
                            <div class="location">Intellect</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="panellists">
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="speaker py-3">
                            <div class="name">Wee Kai Tay</div>
                            <div class="desg">CEO</div>
                            <div class="location">Parkway Shenton</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="panellists">
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="speaker py-3">
                            <div class="name">Dr Ellie Choi</div>
                            <div class="desg">
                              Associate Consultant, Division of Dermatology,
                              Department of Medicine
                            </div>
                            <div class="location">
                              National University Hospital
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-5 part-3 rounded">
            <div class="card-header" id="headingThree">
              <h2 class="mb-0">
                <button
                  class="btn btn-block btn-header-link text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <div class="row">
                    <div class="col-sm-3 col-5 py-3">
                      <div class="prog-part py-4">PM (PART 1)</div>
                    </div>
                    <div class="col-sm-9 col-7 py-3">
                      <div class="prog-title py-4 rounded-right px-4">
                        Who Has Built Bridges Before?
                      </div>
                    </div>
                    <div class="col-sm-3 col-5 py-2"></div>
                    <div class="col-sm-9 col-7 py-2">
                      <div class="prog-detail pl-4 mb-3">
                        Where and how have healthcare & social care worked well
                        together? What are the inspirations and lessons from
                        these stories?
                      </div>
                      <div class="prog-detail pl-4">
                        We bring to you inspiring tales of how differences are
                        bridged, breakthroughs are formed and possibilities are
                        created – to drive meaningful change, movements and
                        innovations. Join us for an afternoon of
                        thought-provoking conversations, where the brightest
                        minds in and outside of Singapore will peel back the
                        layers of conventional thinking, challenge your beliefs
                        and inspire you to transform illness-centric care to
                        wellness-centric care.
                      </div>
                    </div>
                  </div>
                </button>
              </h2>
            </div>
            <div
              id="collapseThree"
              class="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-3 col-5 py-3 prog-head">TIME</div>
                  <div class="col-sm-9 col-7 py-3 prog-head">PROGRAMME</div>
                  <div class="col-sm-3 col-5 py-3 prog-time">
                    1.00pm - 1.25pm (SGT)
                  </div>
                  <div class="col-sm-9 col-7 py-3 prog-detail-content">
                    <div class="speaker pb-4">
                      <div class="name">CentreStage Speaker</div>
                      <div class="detail my-3">
                        <div class="title">
                          Use Everything at your Disposal for Change
                        </div>
                        <div>
                          Known as Thailand’s “Condom King”, Viravaidya’s
                          audacious and innovative advocacy for family planning,
                          HIV prevention and community activation has saved
                          millions of lives and continues to shape a brighter
                          future. Hear how this living legend dismantles taboos,
                          defies societal norms and cajoles the transformation
                          of public health and behaviour.
                        </div>
                      </div>
                      <div
                        class="name pointer"
                        @click="
                          openModal('/speakers/desktop/centerstage 2/popup/1')
                        "
                      >
                        Mechai Viravaidya
                      </div>
                      <div class="desg">Founder & Chairman</div>
                      <div class="location">
                        Population and Community Development Association (PDA),
                        Thailand
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-3 col-5 py-3 prog-time">
                    1.25pm - 1.45pm (SGT)
                  </div>
                  <div class="col-sm-9 col-7 py-3 prog-detail-content">
                    <div class="title">
                      Fireside Chat with Mr Mechai Viravaidya
                    </div>
                    <div class="speaker py-4">
                      <div class="name">Moderated By</div>

                      <div
                        class="name pointer"
                        @click="
                          openModal('/speakers/desktop/chat moderator/popup/3')
                        "
                      >
                        Dr Wong Sweet Fun
                      </div>
                      <div class="desg">
                        Clinical Director, Population Health & Community
                        Transformation
                      </div>
                      <div class="location">
                        Khoo Teck Puat Hospital & Yishun Health
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-3 col-5 py-3 prog-time">
                    1.45pm - 2.45pm (SGT)
                  </div>
                  <div class="col-sm-9 col-7 py-3 prog-detail-content">
                    <div class="title">Faceoff 2023:</div>
                    <div class="detail">
                      <div class="font-weight-bold">
                        “Singaporeans’ health behaviours will only change with
                        governmental interventions’
                      </div>
                      <div class="speaker py-4">
                        <h6 class="desg">Chairperson</h6>
                        <ul>
                          <li>
                            <span
                              class="name pointer"
                              @click="
                                openModal(
                                  '/speakers/desktop/FaceOfChairman/popup/1'
                                )
                              "
                              >Jared Poon</span
                            >, Principal Consultant,
                            <span class="location">Counter-Fictional</span>
                          </li>
                        </ul>
                        <h6 class="desg">Debaters</h6>
                        <ul>
                          <li>
                            <span
                              class="name pointer"
                              @click="
                                openModal('/speakers/desktop/debaters/popup/2')
                              "
                              >Dr Habeebul Rahman</span
                            >, Senior Consultant, Psychiatry,
                            <span class="location">Tan Tock Seng Hospital</span>
                          </li>
                          <li>
                            <span
                              @click="
                                openModal('/speakers/desktop/debaters/popup/4')
                              "
                              class="name pointer"
                              >Dr Joanne Yoong</span
                            >, CEO & Founder,
                            <span class="location">Research For Impact </span>
                          </li>
                          <li>
                            <span
                              @click="
                                openModal('/speakers/desktop/debaters/popup/5')
                              "
                              class="name pointer"
                              >Nur Khairah Abdul Rahim</span
                            >, Co-Founder,
                            <span class="location">Independents</span>
                          </li>
                          <li>
                            <span
                              @click="
                                openModal('/speakers/desktop/debaters/popup/6')
                              "
                              class="name pointer"
                              >Dr Umapathi N. Thirugnanam</span
                            >, Senior Consultant, Dept of Neurology,
                            <span class="location"
                              >National Neuroscience Institute</span
                            >
                          </li>
                          <li>
                            <span
                              @click="
                                openModal('/speakers/desktop/debaters/popup/7')
                              "
                              class="name pointer"
                              >Dr Mary Chong</span
                            >, Associate Professor, Saw Swee Hock School of
                            Public Health,
                            <span class="location"
                              >National University of Singapore</span
                            >
                          </li>
                          <li>
                            <span
                              @click="
                                openModal('/speakers/desktop/debaters/popup/8')
                              "
                              class="name pointer"
                              >Khairul (Khai) Mohd Khair</span
                            >, Senior Consultant, Change Management,
                            <span class="location">Kantar Public</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-3 col-5 py-3 prog-time">
                    2.45pm - 3.00pm (SGT)
                  </div>
                  <div class="col-sm-9 col-7 py-3 prog-detail-content">
                    <div class="speaker">
                      <div class="name">CentreStage Speaker</div>
                      <div class="detail my-3">
                        <div class="title">Tech with a Beating Heart</div>
                        <div>
                          In this digital and virtual age, how can we harness
                          the power of technology to build the necessary bridges
                          between healthcare and our communities, while
                          preserving the humanity and heart in these models of
                          care? Dr Bajwa will share great examples and ideas
                          from the NHS.
                        </div>
                      </div>
                      <div
                        class="name pointer"
                        @click="
                          openModal('/speakers/desktop/centerstage 2/popup/2')
                        "
                      >
                        Dr Junaid Bajwa
                      </div>
                      <div class="desg">
                        NHS Physician/ Board Member, Chief Medical Scientist,
                        Microsoft Research
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-3 col-5 py-3 prog-time">
                    3.00pm - 3.15pm (SGT)
                  </div>
                  <div class="col-sm-9 col-7 py-3 prog-detail-content">
                    <div class="title">Fireside Chat with Dr Junaid Bajwa</div>
                    <div class="speaker">
                      <div class="desg font-weight-bold">Moderated by</div>
                      <div
                        class="name pointer"
                        @click="
                          openModal('/speakers/desktop/chat moderator/popup/1')
                        "
                      >
                        Dr Ravinder Singh Sachdev
                      </div>
                      <div class="desg">
                        Senior Consultant, Continuing & Community Care;
                      </div>
                      <div class="desg">Chief Clinical Informatics Office</div>
                      <div class="location">Tan Tock Seng Hospital</div>
                    </div>
                  </div>

                  <div class="col-sm-3 col-5 py-3 prog-time">
                    3.15pm - 3.45pm (SGT)
                  </div>
                  <div class="col-sm-9 col-7 py-3 prog-detail-content">
                    <div class="title">Afternoon Refreshment</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card part-4 rounded">
            <div class="card-header" id="headingFour">
              <h2 class="mb-0">
                <button
                  class="btn btn-block btn-header-link text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  <div class="row">
                    <div class="col-sm-3 col-5 py-3">
                      <div class="prog-part py-4">PM (PART 2)</div>
                    </div>
                    <div class="col-sm-9 col-7 py-3">
                      <div class="prog-title py-4 rounded-right px-4">
                        Let’s Build Our Bridge!
                      </div>
                    </div>
                    <div class="col-sm-3 col-5 py-2"></div>
                    <div class="col-sm-9 col-7 py-2">
                      <div class="prog-detail pl-4 mb-3">
                        How can you build your own movements, bridges and
                        innovations as we journey towards HealthierSG?
                      </div>
                      <div class="prog-detail pl-4">
                        For the grand finale of CHI INNOVATE, we have curated a
                        closing segment that will arm YOU, as stewards of
                        tomorrow, with practical tips and strategies to Think
                        Boldly, Act Decisively and Build an Impactful Change/
                        Bridge as we journey towards HealthierSG. 
                      </div>
                    </div>
                  </div>
                </button>
              </h2>
            </div>
            <div
              id="collapseFour"
              class="collapse"
              aria-labelledby="headingFour"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-3 col-5 py-3 prog-head">TIME</div>
                  <div class="col-sm-9 col-7 py-3 prog-head">PROGRAMME</div>
                  <div class="col-sm-3 col-5 py-3 prog-time">
                    3.45pm - 4.55pm (SGT)
                  </div>
                  <div class="col-sm-9 col-7 py-3 prog-detail-content">
                    <div class="title">Bridging Friends Forever (B.F.F.)</div>
                    <div class="prog-detail">
                      BFFs always listen to each other’s challenges, bond over
                      commonalities and differences, and meet up to sort things
                      out for each other.
                    </div>
                    <div class="prog-detail mb-3">
                      Listen in on these heart-to-heart chats live on 28 July.
                    </div>
                    <div class="title text-center">BFF Challenges</div>
                    <div class="row">
                      <div class="col-sm-3">
                        <div class="title mb-2">Mental Health</div>
                        <div class="speaker">
                          <div
                            class="name pointer"
                            @click="
                              openModal(
                                '/speakers/desktop/colide panellists/popup/4'
                              )
                            "
                          >
                            Dr Jonty Heaversedge
                          </div>
                          <div class="desg">
                            Chief Medical Officer & Chief Clinical Information
                            Officer
                          </div>
                          <div class="location">
                            South East London Integrated Care System
                          </div>
                          <div class="desg">Clinical Director</div>
                          <div class="location">
                            Imperial College Health Partners
                          </div>
                          <div class="name mt-2">Porsche Poh</div>
                          <div class="desg">Executive Director</div>
                          <div class="location">Silver Ribbon</div>
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="title mb-2">Caregiving</div>
                        <div class="speaker">
                          <div
                            class="name pointer"
                            @click="
                              openModal(
                                '/speakers/desktop/colide panellists/popup/2'
                              )
                            "
                          >
                            Göran Henriks
                          </div>
                          <div class="desg">
                            Chief Executive of Learning and Innovation
                          </div>
                          <div class="location">
                            Region Jönköping County, Sweden
                          </div>
                          <div class="name mt-2">Josie Liow</div>
                          <div class="desg">Co-Chair</div>
                          <div class="location">
                            SingHealth Patient Advocacy Network
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="title mb-2">Disability & Access</div>
                        <div class="speaker">
                          <div class="name pointer">
                            A/Prof Cherdchai Nopmaneejumruslers
                          </div>
                          <div class="desg">
                            Deputy Dean for Service Innovation and
                            Organisational Value
                          </div>
                          <div class="location">
                            Siriraj Hospital, Mahidol University, Bangkok,
                            Thailand
                          </div>
                          <div class="name mt-2">Koh Seng Choon</div>
                          <div class="desg">Executive Director & Founder</div>
                          <div class="location">
                            Project Dignity (Singapore & Hong Kong)
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-3">
                        <div class="title mb-2">Youth & Leadership</div>
                        <div class="speaker">
                          <div class="name">Dr Jonathon Gray</div>
                          <div class="desg">
                            Director of Improvement and Innovation
                          </div>
                          <div class="location">
                            Cardiff and Vale University Health Board
                          </div>
                          <div class="name mt-2">Nancy Ng</div>
                          <div class="desg">
                            Director, Family Support Division, Family Life Group
                          </div>
                          <div class="location">
                            Ministry of Social and Family Development
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-3 col-5 py-3 prog-time">
                    4.55pm - 5.20pm (SGT)
                  </div>
                  <div class="col-sm-9 col-7 py-3 prog-detail-content">
                    <div class="speaker">
                      <div class="name">CentreStage Speaker</div>
                      <div class="title mt-2">
                        What’s Now & What’s Next in Health & Social Change
                      </div>
                      <div class="mb-2">
                        As the curtains draw to a close, Prof Teo YY takes
                        centrestage to survey the mega trends that will impact
                        us in the near future; pointedly argue why healthcare in
                        its current form is not tenable; and present the need
                        for a reform that involves an acceptance of radical new
                        norms in health & social care. We want you to take away
                        not just actionable strategies, but also an awakening, a
                        call to action and a catalyst for change as we move
                        towards a HealthierSG!
                      </div>
                      <div
                        class="name pointer"
                        @click="
                          openModal(
                            '/speakers/desktop/How Can you Build the Bridge/popup/1'
                          )
                        "
                      >
                        Prof Teo Yik Ying
                      </div>
                      <div class="desg">
                        Dean, Saw Swee Hock School of Public Health
                      </div>
                      <div class="location">
                        National University of Singapore
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-3 col-5 py-3 prog-time">
                    5.20pm - 5.30pm (SGT)
                  </div>
                  <div class="col-sm-9 col-7 py-3 prog-detail-content">
                    <div class="title">Closing of CHI INNOVATE 2023</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p class="py-5 mb-0 text-light">
          <i
            >*Programme schedule is subject to changes, accurate as of 10 July
            2023.</i
          >
        </p>
      </div>
    </div>

    <div
      class="modal fade"
      id="popupspeaker"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content border-0" style="background: transparent">
          <div class="modal-body p-0">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              style="color: #000"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="container-fluid">
              <div class="row">
                <div class="col-12 align-self-center">
                  <img alt="img" class="img-fluid" id="popupspeakerImage" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import HeroVideo from "@/components/hero.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "programme",
  components: {
    HeroVideo,
    Footer,
  },
  mounted() {},
  data() {
    return {
      type: "desktop",
    };
  },
  created() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    openModal(path) {
      let image = document.getElementById("popupspeakerImage");
      if (this.type === "desktop") {
        image.src = path + ".png";
        $("#popupspeaker").modal("show");
      } else {
        image.src = path + "-m.png";

        $("#popupspeaker").modal("show");
      }
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    onResize() {
      let mq = window.matchMedia("(max-width: 570px)");
      if (mq.matches) {
        this.type = "mobile";
      } else {
        this.type = "desktop";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("//netdna.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css");

.programme {
  background-image: linear-gradient(rgb(68, 11, 154), rgb(16, 4, 51));

  .pointer {
    cursor: pointer;
  }
  .programme-text {
    background-image: linear-gradient(
      135deg,
      rgb(127, 41, 254),
      rgb(217, 65, 103)
    );
    color: #fff;

    h2 {
      margin-bottom: 0px !important;
      font-family: "Sansrace-Regular";
    }
  }
  .content {
    background-image: linear-gradient(rgb(68, 11, 154), rgb(16, 4, 51));
    .btn:focus,
    .btn.focus {
      box-shadow: none !important;
    }
    .prog-part {
      font-family: "Sansrace-Regular";
      color: #fff;
      font-size: 1.8rem;
    }
    .prog-title {
      background-color: #0000002b;

      font-family: "Sansrace-Regular";
      color: #fff;
      font-size: 1.8rem;
    }
    .prog-detail {
      color: #fff;
      font-family: "Nexa-Light";
    }
    .part-1 {
      background-image: linear-gradient(rgb(191, 48, 113), rgb(224, 42, 105));
      box-shadow: 0px 1px 8px 10px #4a2c76;
    }
    .part-2 {
      background-image: linear-gradient(rgb(220, 65, 214), rgb(149, 65, 250));
      box-shadow: 0px 1px 8px 10px #4a2c76;
    }
    .part-3 {
      background-image: linear-gradient(rgb(43, 208, 200), rgb(83, 60, 206));
      box-shadow: 0px 1px 8px 10px #4a2c76;
    }
    .part-4 {
      background-image: linear-gradient(rgb(237, 173, 96), rgb(217, 65, 103));
      box-shadow: 0px 1px 8px 10px #4a2c76;
    }
    .prog-head {
      background-color: #0000002b;
      color: rgb(244, 211, 94);
      font-family: "Nexa-Bold";
    }
    .prog-time {
      font-family: "Nexa-Bold";
      color: #fff;
      background-color: #00000012;
    }
    .prog-detail-content {
      background-color: #00000012;
      color: #fff;
      .stage {
        font-family: "Nexa-Bold";
        text-transform: uppercase;
        font-size: 0.9rem;
      }
      .title {
        font-family: "Nexa-Bold";
        font-size: 1.2rem;
      }
      .speaker {
        font-size: 0.9rem;
        li {
          margin-bottom: 8px;
        }
        li:last-child {
          margin-bottom: 0px;
        }
        .name {
          font-family: "Nexa-Bold";

          // font-family: "Open Sans", sans-serif;
          // font-weight: bold;
        }
        .desg {
          // font-family: "Open Sans", sans-serif;
          font-family: "Nexa-Light";
        }
        .location {
          font-family: "Nexa-Bold";

          // font-family: "Open Sans", sans-serif;
          // font-weight: bold;
          font-style: italic;
        }
      }
      .panellists {
        font-size: 0.9rem;
        .title {
          font-family: "Open Sans", sans-serif;
          font-weight: bold;
          font-style: italic;
        }
      }
      .detail {
        font-family: "Nexa-Light";
        font-size: 0.9rem;
      }
    }
  }
  .mobile-content {
    .prog-part {
      font-size: 1rem;
    }
    .prog-title {
      font-size: 1rem;
    }
    .prog-detail {
      font-size: 0.8rem;
    }
    .prog-detail-content {
      .stage {
        font-size: 0.8rem;
      }
      .title {
        font-size: 1rem;
      }
      .speaker {
        font-size: 0.7rem;
      }
      .detail {
        font-size: 0.8rem;
      }
    }
  }
  .btn-header-link:after {
    font-family: "FontAwesome";
    color: #fff;
    content: "\f077";

    float: right;
  }
  .btn-header-link.collapsed:after {
    content: "\f078";
  }

  .close {
    position: absolute;
    right: -30px;
    top: 0;
    z-index: 999;
    font-size: 2rem;
    font-weight: normal;
    color: #fff;
    opacity: 1;
  }

  @media only screen and (max-width: 600px) {
    .close {
      position: absolute;
      right: 20px;
      top: 0px;
      z-index: 999;
      font-size: 2rem;
      font-weight: normal;
      color: #000;
      -webkit-text-stroke: 1px black;
      -webkit-text-fill-color: white;
    }
  }
}
</style>
