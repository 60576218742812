import Api from "./Api";
import Csrf from "./Csrf";
const util = {
  async auth_middleware(app, event = null, type = "visit") {
    // if (app.$route.name == "login")
    // return;

    if (!type) type = "visit";
    let path = app.$route.path;

    if (event) {
      path = event;
      type = type;
    }

    let token = localStorage.getItem("participant_token");
    if (!token) {
      if (app.$route.meta.requiresAuth) {
        this.redirectToHome(app);
      }
      return;
      // un auth logic
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
      },
      params: {
        type: type,
        event: path,
      },
    };

    // required auth route

    Api.get("/me", config)
      .then((res) => {
        // console.log("%cutil.js line:40 res", "color: #007acc;", res);
        // return;
        if (!res.data.error) {
          if (app.$route.meta.onlyGuest) {
            app.$router.push("/");
          }

          // {
          app.$store.commit("user", res.data.user);
          //   app.$store.commit("user", res.data.user);
          // }
          // if (res.data.user.booth) {
          //   if (app.$route.path != "/exhibitor/chat")
          //     app.$router.push("/exhibitor/chat");
          // }
        } else {
          app.$store.commit("user", { id: 0 });

          if (app.$route.meta.requiresAuth) {
            this.redirectToHome(app);
          }
        }
      })
      .catch((res) => {
        // console.log('%cutil.js line:40 res', 'color: #007acc;', res);
        // return;
        app.$store.commit("user", { id: 0 });

        if (app.$route.meta.requiresAuth) {
          this.redirectToHome(app);
        }
      });
    // await Csrf.getCookie()
    //   .then((res) => {
    //     Api.post(`is_logged_in?event=${path}&type=${type}`)
    //       .then((res) => {
    //         app.$store.commit("user", res.data.guest);
    //         if (app.$route.meta.guest) app.$router.push("/streampage");
    //       })
    //       .catch((e) => {
    //         console.log("%cutil.js line:26 e", "color: #007acc;", e);
    //         if (app.$route.meta.requiresAuth) app.$router.push("/");
    //       });
    //   })
    //   .catch((res) => {
    //     if (app.$route.meta.requiresAuth) app.$router.push("/");
    //   });
  },

  getMessageCount() {
    let count = localStorage.getItem("message_count");
    if (!count) this.resetMessageCount();
    return localStorage.getItem("message_count");
  },
  resetMessageCount() {
    localStorage.setItem("message_count", 0);
  },
  incrementMessageCount() {
    let count = this.getMessageCount();
    count++;
    localStorage.setItem("message_count", count);
  },

  getExhibitionMessageCount(booth) {
    let ex_count = localStorage.getItem("exhibition_message_count");

    if (!ex_count) {
      let default_message_count = {
        sif: 0,
        usc: 0,
      };

      localStorage.setItem(
        "exhibition_message_count",
        JSON.stringify(default_message_count)
      );
    }

    ex_count = JSON.parse(localStorage.getItem("exhibition_message_count"));

    return ex_count[booth];
  },

  incrementExhibitionMessageCount(booth) {
    let count = this.getExhibitionMessageCount(booth);
    count++;
    this.setExhibitionMessageCount(
      JSON.parse(localStorage.getItem("exhibition_message_count")),
      booth,
      count
    );
  },
  setExhibitionMessageCount(booths, booth, count) {
    let default_message_count = booths;
    default_message_count[booth] = count;
    localStorage.setItem(
      "exhibition_message_count",
      JSON.stringify(default_message_count)
    );
  },
  resetExhibitionMessageCount(booth) {
    let count = this.getExhibitionMessageCount(booth);
    count = 0;
    this.setExhibitionMessageCount(
      JSON.parse(localStorage.getItem("exhibition_message_count")),
      booth,
      count
    );
  },
  redirectToHome(app) {
    // return;
    app.$router.push("/");
  },
  getAuthConfig() {
    let token = localStorage.getItem("participant_token");
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
      },
    };
  },
};
export default util;
