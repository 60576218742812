<template>
  <div class="hero">
    <video
      autoplay
      muted
      loop
      playsinline
      class="hero-video"
      v-if="isUADesktop || (!isPotrait && !isUAMobile)"
    >
      <source src="@/assets/img/desktop_new.mp4" type="video/mp4" />
    </video>
    <video autoplay muted loop playsinline class="hero-video" v-else>
      <source src="@/assets/img/mobile_new.mp4" type="video/mp4" />
    </video>
  </div>
</template>

<script>
export default {
  name: "hero",

  mounted() {
    this.globalOrientationCheck();
    this.globalDeviceCheck();
  },
};
</script>

<style lang="scss" scoped>
.hero {
  background-image: linear-gradient(
    90deg,
    rgb(127, 41, 254),
    rgb(217, 65, 103)
  );
  // min-height: 100vh;
  position: relative;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: flex-end;
  //   align-items: center;
  overflow: hidden;
  .hero-video {
    z-index: -1;
    // position: absolute;
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
