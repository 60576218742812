import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    settings: {},
    user: {
      id: 0,
    },
    selected_chat_channel: 0,
    online_users: [],
    newMessage: {},
    newExhibitionMessage: {},

  },
  mutations: {
    user(state, data) {
      state.user = data;
    },
    settings(state, data) {
      state.settings = data;
    },
    online_users(state, data) {
      state.online_users = data;
    },
    selected_chat_channel(state, data) {
      state.selected_chat_channel = data;
    },
    newMessage(state, data) {
      state.newMessage = data;
    },
    newExhibitionMessage(state, data) {
      state.newExhibitionMessage = data;
    },
  },
  actions: {},
  modules: {},
});
