<template>
  <div class="footer-com">
    <div class="footer">
      <div v-if="type == 'desktop'">
        <div class="container-xl">
          <div class="row py-3">
            <div class="col-3">
              <p class="font-weight-bold">Organised by</p>
              <img
                src="@/assets/img/footer/chi-logo.png"
                alt=""
                class="img-fluid"
              />
            </div>
            <div class="col-3 text-center">
              <p class="font-weight-bold">Supported by</p>
              <img
                src="@/assets/img/footer/logo-moh.png"
                alt=""
                class="img-fluid"
              />
            </div>
            <div
              class="col-4 ng-teng d-flex flex-column justify-content-end"
            >
              <img
                src="@/assets/img/footer/ng.png"
                alt=""
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="container-xl">
          <div class="row pt-3">
            <div class="col-6 py-1">
              <p class="font-weight-bold">Organised by</p>
              <img
                src="@/assets/img/footer/chi-logo.png"
                alt=""
                class="img-fluid"
              />
            </div>
            </div>
            <div class="row pb-3">


            <div class="col-6 py-1">
              <p class="font-weight-bold">Supported by</p>
              <img
                src="@/assets/img/footer/logo-moh.png"
                alt=""
                class="img-fluid"
              />
            </div>
            <div
              class="col-6 py-1 ng-teng-mobile d-flex flex-column justify-content-center"
            >
              <img
                src="@/assets/img/footer/ng.png"
                alt=""
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright py-2">
      <div class="container-xl">
        <div class="d-flex justify-content-between">
          <p>Copyright © to Klobbi.sg</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer",
  data() {
    return {
      type: "desktop",
    };
  },

  created() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      let mq = window.matchMedia("(max-width: 600px)");
      if (mq.matches) {
        this.type = "mobile";
      } else {
        this.type = "desktop";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-com {
    .footer {
    border-bottom: 1px solid #eaebee;
    background: #f8f8f8;
    .ng-teng {
      p {
        font-size: 0.7rem;
      }
    }
    .ng-teng-mobile {
      font-size: 0.4rem;
    }
  }
  .copyright {
    p {
      color: #8a91a1;
    }
  }
}
</style>
