<template>
  <div class="register pb-5">
    <div class="hero">
      <img
        src="@/assets/img/ticket/banner.png"
        class="img-fluid"
        alt="hero-reg"
        v-if="type == 'desktop'"
      />
      <img
        src="@/assets/img/ticket/banner-mobile.png"
        class="img-fluid border"
        alt="hero-reg"
        v-else
      />
    </div>
    <div class="container-lg py-4">
      <h1 class="text-center">Payment Failed</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "canceled",
  data() {
    return {
      type: "desktop",
    };
  },
  created() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      let mq = window.matchMedia("(max-width: 570px)");
      if (mq.matches) {
        this.type = "mobile";
      } else {
        this.type = "desktop";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.register {
  h1 {
    font-family: "Nexa-Bold";
  }
  p{
    font-family: "Nexa-Light";
  }
}
</style>
