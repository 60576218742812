<template>
  <div class="nv">
    <div
      class="container-lg px-1"
      v-if="isUADesktop || (!isPotrait && !isUAMobile)"
    >
      <div
        class="desktop-nav d-flex justify-content-between text-light font-nexa py-3"
      >
        <div class="d-flex justify-content-between align-items-center">
          <div class="logo mr-3">
            <a href="/"
              ><h4 class="font-sansrace mb-0">CHI INNOVATE 2023</h4></a
            >
          </div>
          <div class="home mx-3">
            <router-link
              :to="{
                name: 'landing',
              }"
              ><h6>Home</h6></router-link
            >
          </div>

          <div class="speaker mx-3">
            <router-link
              :to="{
                name: 'speaker',
              }"
              ><h6>Speakers</h6></router-link
            >
          </div>
          <!-- <div class="PROGRAMME mx-3">
            <router-link
              :to="{
                name: 'programme',
              }"
            >
              <h6>Programme</h6></router-link
            >
          </div> -->
          <div class="PROGRAMME mx-3">
            <router-link
              :to="{
                name: 'nhip',
              }"
            >
              <h6>National HIP Medals</h6></router-link
            >
          </div>

          <div class="sponsor mx-3">
            <router-link
              :to="{
                name: 'sponsors',
              }"
            >
              <h6>Sponsorship and Exhibition</h6></router-link
            >
          </div>
          <div class="PROGRAMME mx-3">
            <router-link
              :to="{
                name: 'aboutus',
              }"
            >
              <h6>About Us</h6></router-link
            >
          </div>
        </div>
        <div class="sales btn rounded-pill">
          <a href="/ticket-sales" class=" "> <h6>REGISTER NOW</h6></a>
        </div>
      </div>
    </div>

    <nav class="navbar navbar-expand-lg font-nexa" v-else>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <!-- <span class="navbar-toggler-icon"></span>
         -->
        <svg viewBox="0 0 100 80" width="30" height="40">
          <rect width="80" height="20" rx="8" fill="#fff"></rect>
          <rect y="30" width="100" height="20" rx="8" fill="#fff"></rect>
          <rect y="60" width="80" height="20" rx="8" fill="#fff"></rect>
        </svg>
      </button>
      <router-link
        :to="{
          name: 'landing',
        }"
        ><p class="mb-0 font-sansrace text-light">
          CHI INNOVATE 2023
        </p></router-link
      >

      <div class="">
        <router-link
          :to="{
            name: 'ticket',
          }"
          class="sales-mobile btn rounded-pill"
          >REGISTER NOW</router-link
        >
      </div>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <a href="/" class="nav-link">HOME</a>
          </li>
          <!-- <li class="nav-item">
            <a href="programme" class="nav-link">PROGRAMME</a>
          </li> -->
          <li class="nav-item">
            <a href="/speaker" class="nav-link">SPEAKERS</a>
          </li>

          <li class="nav-item">
            <a href="/nhip" class="nav-link text-uppercase"
              >National HIP Medals</a
            >
          </li>
          <li class="nav-item">
            <a
              href="/sponsorship-and-exhibition"
              class="nav-link text-uppercase"
              >Sponsorship and Exhibition</a
            >
          </li>
          <li class="nav-item">
            <a href="/about-us" class="nav-link text-uppercase">About Us</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "navbar",
  data() {
    return {
      type: "desktop",
    };
  },
  mounted() {
    this.globalOrientationCheck();
    this.globalDeviceCheck();
  },
};
</script>

<style lang="scss" scoped>
.nv {
  z-index: 10;
  position: relative;
  padding: 10px;
  position: absolute;
  width: 100%;
  .font-nexa {
    font-family: "Nexa-Light";
  }
  a {
    text-decoration: none;
    color: #fff;
  }
  .home {
    position: relative;
  }
  .PROGRAMME {
    position: relative;
  }

  .speaker {
    position: relative;
  }
  .medals {
    position: relative;
  }
  .CHISEL {
    position: relative;
  }
  .sponsor {
    position: relative;
  }
  .desktop-nav {
    .router-link-exact-active:before {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 0;
      right: 0;
      width: 100%;
      height: 5px;
      border-radius: 5px;
      background: rgb(217, 65, 103);
      // background-image: linear-gradient(to right, #ff7c19, #ffd073);
    }
  }
  .navbar {
    padding: 0.5rem 0rem;
  }
  .navbar-nav {
    background: #00000063;
  }
  h6 {
    margin-bottom: 0px !important;
    font-weight: normal;
  }
  .sales {
    background-image: linear-gradient(
      335deg,
      rgb(127, 41, 254),
      rgb(217, 65, 103)
    );
    // padding: 8px;
    // border-radius: 32px;
  }
  .sales-mobile {
    background-image: linear-gradient(
      335deg,
      rgb(127, 41, 254),
      rgb(217, 65, 103)
    );
    // padding: 3px 4px;
    // border-radius: 16px;
    font-size: 0.8rem;
  }
  .social {
    display: flex;
    flex-direction: column;
    width: 30px;
  }
  .close {
    position: absolute;
    right: -30px;
    top: 0;
    z-index: 999;
    font-size: 2rem;
    font-weight: normal;
    color: #fff;
    opacity: 1;
  }
  @media only screen and (max-width: 600px) {
    .close {
      position: absolute;
      right: 20px;
      top: 0px;
      z-index: 999;
      font-size: 2rem;
      font-weight: normal;
      color: #000;
      -webkit-text-stroke: 1px black;
      -webkit-text-fill-color: white;
    }
  }
}
</style>
