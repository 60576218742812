var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nv"},[(_vm.isUADesktop || (!_vm.isPotrait && !_vm.isUAMobile))?_c('div',{staticClass:"container-lg px-1"},[_c('div',{staticClass:"desktop-nav d-flex justify-content-between text-light font-nexa py-3"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_vm._m(0),_c('div',{staticClass:"home mx-3"},[_c('router-link',{attrs:{"to":{
              name: 'landing',
            }}},[_c('h6',[_vm._v("Home")])])],1),_c('div',{staticClass:"speaker mx-3"},[_c('router-link',{attrs:{"to":{
              name: 'speaker',
            }}},[_c('h6',[_vm._v("Speakers")])])],1),_c('div',{staticClass:"PROGRAMME mx-3"},[_c('router-link',{attrs:{"to":{
              name: 'nhip',
            }}},[_c('h6',[_vm._v("National HIP Medals")])])],1),_c('div',{staticClass:"sponsor mx-3"},[_c('router-link',{attrs:{"to":{
              name: 'sponsors',
            }}},[_c('h6',[_vm._v("Sponsorship and Exhibition")])])],1),_c('div',{staticClass:"PROGRAMME mx-3"},[_c('router-link',{attrs:{"to":{
              name: 'aboutus',
            }}},[_c('h6',[_vm._v("About Us")])])],1)]),_vm._m(1)])]):_c('nav',{staticClass:"navbar navbar-expand-lg font-nexa"},[_c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarNav","aria-controls":"navbarNav","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('svg',{attrs:{"viewBox":"0 0 100 80","width":"30","height":"40"}},[_c('rect',{attrs:{"width":"80","height":"20","rx":"8","fill":"#fff"}}),_c('rect',{attrs:{"y":"30","width":"100","height":"20","rx":"8","fill":"#fff"}}),_c('rect',{attrs:{"y":"60","width":"80","height":"20","rx":"8","fill":"#fff"}})])]),_c('router-link',{attrs:{"to":{
        name: 'landing',
      }}},[_c('p',{staticClass:"mb-0 font-sansrace text-light"},[_vm._v(" CHI INNOVATE 2023 ")])]),_c('div',{},[_c('router-link',{staticClass:"sales-mobile btn rounded-pill",attrs:{"to":{
          name: 'ticket',
        }}},[_vm._v("REGISTER NOW")])],1),_vm._m(2)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo mr-3"},[_c('a',{attrs:{"href":"/"}},[_c('h4',{staticClass:"font-sansrace mb-0"},[_vm._v("CHI INNOVATE 2023")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sales btn rounded-pill"},[_c('a',{staticClass:" ",attrs:{"href":"/ticket-sales"}},[_c('h6',[_vm._v("REGISTER NOW")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarNav"}},[_c('ul',{staticClass:"navbar-nav"},[_c('li',{staticClass:"nav-item active"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/"}},[_vm._v("HOME")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/speaker"}},[_vm._v("SPEAKERS")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link text-uppercase",attrs:{"href":"/nhip"}},[_vm._v("National HIP Medals")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link text-uppercase",attrs:{"href":"/sponsorship-and-exhibition"}},[_vm._v("Sponsorship and Exhibition")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link text-uppercase",attrs:{"href":"/about-us"}},[_vm._v("About Us")])])])])}]

export { render, staticRenderFns }