import Vue from "vue";
import VueRouter from "vue-router";
import landing from "../views/landing.vue";
import success from "../views/success.vue";
import canceled from "../views/canceled.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "landing",
    component: landing,
  },
  {
    path: "/success",
    name: "success",
    component: success,
  },
  {
    path: "/failed",
    name: "canceled",
    component: canceled,
  },
  {
    path: "/ticket-sales",
    name: "ticket",
    component: () => import("../views/ticket.vue"),
    // meta: { requiresAuth: true },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/auth/register.vue"),
  },
  {
    path: "/nhip",
    name: "nhip",
    component: () => import("../views/nhip.vue"),
    // meta: { requiresAuth: true },
  },
  {
    path: "/sponsorship-and-exhibition",
    name: "sponsors",
    component: () => import("../views/sponsors.vue"),
  },
  {
    path: "/speaker",
    name: "speaker",
    component: () => import("../views/speaker.vue"),
    // meta: { requiresAuth: true },
  },
  // {
  //   path: "/programme",
  //   name: "programme",
  //   component: () => import("../views/programme.vue"),
  
  // },
   {
    path: "/about-us",
    name: "aboutus",
    component: () => import("../views/aboutus.vue"),
  
  },
  
  {
    path: "/livestream",
    name: "livestream",
    component: () => import("../views/livestream.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/healthCareInnoMatch",
    name: "healthCareInnoMatch",
    component: () => import("../views/healthInnomatch.vue"),
    // meta: { requiresAuth: true },
  },
  {
    path: "/login/:hash",
    name: "login",
    component: () => import("../views/auth/login.vue"),
    meta: { guest: true },
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
