import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "./assets/css/sass/app.scss";
import util from "@/assets/js/util";
import UAParser from "ua-parser-js";

import Csrf from "@/assets/js/Csrf";
import Api from "@/assets/js/Api";

Vue.config.productionTip = false;

import Echo from "laravel-echo";
window.Pusher = require("pusher-js");

window.Echo = new Echo({
  broadcaster: "pusher",
  key: "c37bcaa254a2fb40c681",
  cluster: "mt1",
  wsHost: "chiinnovate2022-admin.klobbi.com",
  // wsHost: location.hostname,

  // wsPort: 8443,

  wssPort: 8443,
  authorizer: (channel, options) => {
    return {
      authorize: (socketId, callback) => {
        Csrf.getCookie().then((res) => {
          Api.post(
            "/broadcasting/auth",
            {
              socket_id: socketId,
              channel_name: channel.name,
            },
            util.getAuthConfig()
          )
            .then((response) => {
              // console.log(response);
              callback(false, response.data);
            })
            .catch((error) => {
              // console.log(error);

              callback(true, error);
            });
        });
      },
    };
  },
});

Vue.mixin({
  data() {
    return {
      isPotrait: false,
      isUADesktop: false,
      isUAMobile: false,
      isUATablet: false,
    };
  },
  methods: {
    globalOrientationCheck() {
      var mql = window.matchMedia("(orientation: portrait)");

      if (mql.matches) {
        this.isPotrait = true;
      } else {
        this.isPotrait = false;
      }

      mql.addListener(function (m) {
        if (m.matches) {
          this.isPotrait = true;
        } else {
          this.isPotrait = false;
        }
      });
    },
    globalDeviceCheck: function () {
      let parser = new UAParser();
      let parser_result = parser.getResult();
      console.log(parser_result);

      if (parser_result.device.type == "mobile") {
        this.isUAMobile = true;
        console.log("isUAMobile", this.isUAMobile);
      } else if (parser_result.device.type == "tablet") {
        console.log("here");
        this.isUATablet = true;
        console.log("isUATablet", this.isUATablet);
      } else {
        this.isUADesktop = true;
      }
    },
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
