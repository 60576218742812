import axios from "axios";

let Api = axios.create({
  baseURL: "https://admin-chiinnovate2023.klobbi.com/api/",
  // baseURL: "http://localhost:8000/api",
});

Api.defaults.withCredentials = true;

export default Api;
