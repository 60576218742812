<template>
  <div id="app">
    <button @click="scrollToTop()" id="toTop" title="Go to top">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="currentColor"
        class="bi bi-chevron-up"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
        />
      </svg>
    </button>
    <navbar v-if="$route.path !== '/register' && $route.path !== '/success' && $route.path !== '/failed'" />
    <router-view />
  </div>
</template>
<script>
import navbar from "@/components/navbar";
import util from "@/assets/js/util";
import Api from "@/assets/js/Api";
import { mapState } from "vuex";
export default {
  name: "appt",
  components: {
    navbar,
  },
  computed: mapState(["user", "settings"]),
  watch: {
    $route() {
      util.auth_middleware(this);
    },
  },
  mounted() {
    util.auth_middleware(this);
    var mybutton = document.getElementById("toTop");

    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function () {
      scrollFunction();
    };

    function scrollFunction() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        mybutton.style.display = "block";
      } else {
        mybutton.style.display = "none";
      }
    }

    // When the user clicks on the button, scroll to the top of the document
    function topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
    this.getSettingsFromApi();
    this.watchSocketChanges();
    // if (!localStorage.getItem("participant_token")) {
    //   Api.post("login").then((res) => {
    //     console.log("%cApp.vue line:49 res", "color: #007acc;", res);
    //     localStorage.setItem("participant_token", res.data.token);
    //   });
    // }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    watchSocketChanges() {
      Echo.channel("trigger-change").listen("TriggerChange", (e) => {
        this.getChangeType(e.message);
      });
    },
    getSettingsFromApi() {
      Api.get("/get_settings").then((res) => {
        this.setSettings(res.data.data);
      });
    },
    setSettings(settings) {
      if (settings) localStorage.setItem("settings", JSON.stringify(settings));
      if (localStorage.getItem("settings")) {
        let settings = JSON.parse(localStorage.getItem("settings"));
        this.$store.commit("settings", settings);
        return;
      }
      this.$store.commit("settings", {});
    },
    getChangeType(message) {
      if (message.type == "settings") this.setSettings(message.data);
      if (message.type == "device-limit") this.triggerLogout(message.data);
      if (message.type == "refresh") location.reload();
    },
    triggerLogout(id) {
      console.log("%cApp.vue line:47 this.user", "color: #007acc;", this.user);
      if (id.id == this.user.id) {
        location.reload();
        // location.href = "/device-limit-exceed";
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  #toTop {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    font-size: 18px;
    border: none;
    outline: none;
    background-color: grey;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 4px;
  }

  #toTop:hover {
    background-color: #555;
  }
}
</style>
