<template>
  <div class="landing">
    <HeroVideo />

    <div class="join-event">
      <div class="container-xl">
        <div class="row py-4">
          <div
            class="col-lg-7 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center"
          >
            <div class="row" v-if="type == 'desktop'">
              <div class="col-12">
                <h2 class="font-spaced">Join The Event</h2>
              </div>
              <div class="col-12">
                <h4 class="font-spaced">
                  Countdown to event:
                  <span class="text-uppercase">CHI INNOVATE 2023</span>
                </h4>
              </div>
            </div>
            <div class="row text-center" v-else>
              <div class="col-12">
                <h2 class="font-spaced">Join The Event</h2>
              </div>
              <div class="col-12">
                <h5 class="font-spaced">
                  Countdown to event:
                  <span class="text-uppercase">CHI INNOVATE 2023</span>
                </h5>
              </div>
            </div>
          </div>
          <div
            class="col-lg-5 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center"
          >
            <div class="row">
              <div class="col-2 text-center">
                <h3>{{ days }}</h3>
                <p>DAYS</p>
              </div>
              <div class="col-1">
                <div class="line"></div>
              </div>
              <div class="col-3 text-center">
                <h3>{{ hours }}</h3>
                <p>HOURS</p>
              </div>
              <div class="col-3 text-center">
                <h3>{{ minutes }}</h3>
                <p>MINUTE</p>
              </div>
              <div class="col-3 text-center">
                <h3>{{ seconds }}</h3>
                <p>SECONDS</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="about">
      <div class="container-xl">
        <div class="row py-3">
          <div class="col-sm-4">
            <img
              src="@/assets/img/landing/about_chi/About CHI image.png"
              class="img-fluid"
              alt=""
              v-if="type == 'desktop'"
            />
            <img
              src="@/assets/img/landing/about_chi/mobile-about.png"
              class="img-fluid"
              alt=""
              v-else
            />
          </div>
          <div class="col-sm-8 text-light">
            <img
              src="@/assets/img/landing/about_chi/about chi text.png"
              v-if="type == 'desktop'"
              class="img-fluid"
              alt=""
            />
            <img
              src="@/assets/img/landing/about_chi/mobile-text.png"
              class="img-fluid"
              v-else
              alt=""
            />
            <div class="bg-rectangle mb-2">
              <p class="mb-0">Building Bridges: Health & Social Care</p>
            </div>
            <p>
              Healthcare is expanding our focus: from healthcare to health; from
              patients to people; from clinical centres to communities of care.
            </p>
            <p>
              CHI INNOVATE 2023 will galvanise us to breach traditional
              boundaries and work with community partners to catalyse health and
              social change, ultimately creating more sustainable healthcare and
              healthy communities.
            </p>
          </div>
        </div>
        <div class="prev-conf py-3 text-center">
          <h4 class="font-spaced font-weight-bold mb-0 text-light">WATCH</h4>
          <h3 class="font-spaced font-weight-bold text-light">
            PREVIOUS CONFERENCES
          </h3>
          <div class="button-container">
            <div class="carousel-buttons">
              <button class="carousel-button prev">&#8249;</button>
            </div>
            <div class="line"></div>
            <div class="button-wrapper">
              <div class="button-group">
                <button
                  :class="{ 'button-active': current == 0 }"
                  @click="openLink(videos[2022], 0)"
                >
                  2022
                </button>
                <button
                  :class="{ 'button-active': current == 1 }"
                  @click="openLink(videos[2021], 1)"
                >
                  2021
                </button>
                <button
                  :class="{ 'button-active': current == 2 }"
                  @click="openLink(videos[2020], 2)"
                >
                  2020
                </button>
                <button
                  :class="{ 'button-active': current == 3 }"
                  @click="openModal(videos[2019], 3)"
                >
                  2019
                </button>
              </div>
            </div>

            <div class="carousel-buttons">
              <button class="carousel-button next">&#8250;</button>
            </div>
          </div>

          <!-- <div class="row">
          <div
            class="col-lg-3 col-md-3 col-sm-6 col-6 d-flex flex-column align-items-center"
          >
            <img
              src="@/assets/img/home/Videos thubnail/2022.png"
              alt="2022"
              class="img-fluid"
              style="cursor: pointer"
              @click="openModal('https://www.youtube.com/embed/L1ot3SRsRMY')"
            />
            <p class="font-weight-bold pt-1">2022</p>
          </div>
          <div
            class="col-lg-3 col-md-3 col-sm-6 col-6 d-flex flex-column align-items-center"
          >
            <a
              href="https://www.youtube.com/playlist?list=PLTrhD5VOFZmpTbZQW9Y5r6K1KogwktE2c"
              target="_blank"
            >
              <img
                src="@/assets/img/home/Videos thubnail/2021.png"
                alt="2021"
                class="img-fluid"
                style="cursor: pointer"
              />
            </a>
            <p class="font-weight-bold pt-1">2021</p>
          </div>
          <div
            class="col-lg-3 col-md-3 col-sm-6 col-6 d-flex flex-column align-items-center"
          >
            <a
              href="https://www.youtube.com/playlist?list=PLTrhD5VOFZmqv3n_Ncc5Yf_Th372lr8gJ"
              target="_blank"
            >
              <img
                src="@/assets/img/home/Videos thubnail/2020.png"
                alt="2020"
                class="img-fluid"
                style="cursor: pointer"
              />
            </a>
            <p class="font-weight-bold pt-1">2020</p>
          </div>
          <div
            class="col-lg-3 col-md-3 col-sm-6 col-6 d-flex flex-column align-items-center"
          >
            <img
              src="@/assets/img/home/Videos thubnail/2019.png"
              alt="2019"
              class="img-fluid"
              style="cursor: pointer"
              @click="openModal('https://www.youtube.com/embed/cdC0i0ZbqMQ')"
            />
            <p class="font-weight-bold pt-1">2019</p>
          </div>
        </div> -->
        </div>
      </div>
    </div>
    <Programme />
  
    <div class="contact py-3 text-center">
      <div class="container" :class="{ 'mobile-contact': type != 'desktop' }">
        <div class="row">
          <div class="col-sm-8 col-8 d-flex align-items-center">
            <h3 class="font-spaced">CONTACT US:</h3>
            <a
              href="mailto:conference@chi.sg"
              class="ml-3 d-flex align-items-center"
              target="_blank"
            >
              <span>
                <img
                  src="@/assets/img/home/mail.png"
                  alt=""
                  width="26"
                  class="img-fluid pr-1" /></span
              >conference@chi.sg
            </a>
          </div>
          <div
            class="col-sm-4 col-4 d-flex align-items-center justify-content-center"
          >
            <a
              href="https://for.sg/chi-mailinglist"
              class="btn btn-mail rounded-pill"
              target="_blank"
              >Join Our Mailing List</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="socials" v-if="type == 'desktop'">
      <div class="container-xl">
        <div class="row py-5 text-light">
          <div class="col-12 text-center mb-4">
            <h3 class="font-spaced mb-0">OUR SOCIALS</h3>
          </div>
          <div class="col-sm-3 mb-3">
            <a
              href="https://www.linkedin.com/company/chi-centre-for-healthcare-innovation/"
              target="_blank"
              class="text-decoration-none text-light d-flex align-items-center justify-content-center"
            >
              <img
                src="@/assets/img/landing/social/ln.png"
                width="24"
                height="24"
                alt=""
              />
              <p class="mb-0 ml-2">CHI - Centre for Healthcare Innovation</p>
            </a>
          </div>
          <div class="col-sm-3 mb-3">
            <a
              href="https://www.facebook.com/CentreForHealthcareInnovation/"
              target="_blank"
              class="text-decoration-none text-light d-flex align-items-center justify-content-center"
            >
              <img
                src="@/assets/img/landing/social/fb.png"
                width="24"
                height="24"
                alt=""
              />
              <p class="mb-0 ml-2">CentreforHealthcareInnovation</p>
            </a>
          </div>
          <div class="col-sm-3 mb-3">
            <a
              href="https://www.instagram.com/chinnovationsg/?hl=en"
              target="_blank"
              class="text-decoration-none text-light d-flex align-items-center justify-content-center"
            >
              <img
                src="@/assets/img/landing/social/insta.png"
                width="24"
                height="24"
                alt=""
              />
              <p class="mb-0 ml-2">chinnovationsg</p>
            </a>
          </div>
          <div class="col-sm-3 mb-3">
            <a
              href="https://www.youtube.com/@centreforhealthcareinnovat158"
              target="_blank"
              class="text-decoration-none text-light d-flex align-items-center justify-content-center"
            >
              <img
                src="@/assets/img/landing/social/yt.png"
                width="24"
                height="24"
                alt=""
              />
              <p class="mb-0 ml-2">Centre for Healthcare Innovation</p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="socials-mobile" v-else>
      <div class="container-xl">
        <div class="row py-5 text-light">
          <div class="col-12 text-center mb-4">
            <h3 class="font-spaced mb-0">OUR SOCIALS</h3>
          </div>
          <div class="col-6 mb-3">
            <a
              href="https://www.linkedin.com/company/chi-centre-for-healthcare-innovation/"
              target="_blank"
              class="text-decoration-none text-light d-flex align-items-center"
            >
              <img
                src="@/assets/img/landing/social/ln.png"
                width="16"
                height="16"
                alt=""
              />
              <p class="mb-0 ml-2">CHI - Centre for Healthcare Innovation</p>
            </a>
          </div>
          <div class="col-6 mb-3">
            <a
              href="https://www.facebook.com/CentreForHealthcareInnovation/"
              target="_blank"
              class="text-decoration-none text-light d-flex align-items-center"
            >
              <img
                src="@/assets/img/landing/social/fb.png"
                width="16"
                height="16"
                alt=""
              />
              <p class="mb-0 ml-2">CentreforHealthcareInnovation</p>
            </a>
          </div>
          <div class="col-6 mb-3">
            <a
              href="https://www.instagram.com/chinnovationsg/?hl=en"
              target="_blank"
              class="text-decoration-none text-light d-flex align-items-center"
            >
              <img
                src="@/assets/img/landing/social/insta.png"
                width="16"
                height="16"
                alt=""
              />
              <p class="mb-0 ml-2">chinnovationsg</p>
            </a>
          </div>
          <div class="col-6 mb-3">
            <a
              href="https://www.youtube.com/@centreforhealthcareinnovat158"
              target="_blank"
              class="text-decoration-none text-light d-flex align-items-center"
            >
              <img
                src="@/assets/img/landing/social/yt.png"
                width="16"
                height="16"
                alt=""
              />
              <p class="mb-0 ml-2">Centre for Healthcare Innovation</p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <div
      class="modal fade"
      id="videoModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content border-0" style="background: transparent">
          <div class="modal-body p-0">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              style="color: #000"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <!-- <div class="container-fluid">
              <div class="row">
                <div class="col-12 align-self-center">
                  <iframe
                    width="100%"
                    height="500"
                    :src="videoSrc"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div> -->
            <div
              class="embed-responsive embed-responsive-16by9"
              v-if="!hide_video"
            >
              <iframe
                class="embed-responsive-item"
                src=""
                id="video"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>

    
  </div>
</template>

<script>
import HeroVideo from "@/components/hero.vue";
import Footer from "@/components/footer.vue";
import Programme from "@/components/programme.vue";

import $ from "jquery";
export default {
  name: "landing",
  components: {
    HeroVideo,
    Programme,
    Footer,
  },

  data() {
    console.log("hrere");
    return {
      type: "desktop",
      currentSpeakerImage: "",
      videoSrc: "https://www.youtube.com/embed/cdC0i0ZbqMQ",
      days: null,
      hours: null,
      minutes: null,
      seconds: null,
      isEnded: null,
      hide_video: true,
      // dateAndTime = "2022"+"-"+"09"+"-"+"09"+"T"+"09"+":"+"30"+":00+08:00",
      endDate: new Date("2023-07-28T09:00:00+08:00"),
      current: 0,
      videos: {
        2019: "https://www.youtube.com/embed/cdC0i0ZbqMQ",
        2020: "https://www.youtube.com/playlist?list=PLTrhD5VOFZmqv3n_Ncc5Yf_Th372lr8gJ",
        2021: "https://www.youtube.com/playlist?list=PLTrhD5VOFZmpTbZQW9Y5r6K1KogwktE2c",
        2022: "https://www.youtube.com/playlist?list=PLTrhD5VOFZmocGcY2hMpjCFtuG1_QrQyc ",
      },
      speakers: [
        {
          dImg: "/landing/desktop/speaker/1.png",
          dPopup: "/landing/desktop/popup/1.png",
          mImg: "/landing/mobile/speaker/1.png",
          mPopup: "/landing/mobile/popup/1.png",
          detail: `Clinical Director<br/>
Centre for Healthcare Innovation
(Chairperson)`,
        },
        {
          dImg: "/landing/desktop/speaker/2.png",
          dPopup: "/landing/desktop/popup/2.png",
          mImg: "/landing/mobile/speaker/2.png",
          mPopup: "/landing/mobile/popup/2.png",
          detail: `Director<br/>
Sector & Partnership Division<br/>
Agency for Integrated Care`,
        },
        {
          dImg: "/landing/desktop/speaker/3.png",
          dPopup: "/landing/desktop/popup/3.png",
          mImg: "/landing/mobile/speaker/3.png",
          mPopup: "/landing/mobile/popup/3.png",
          detail: `Deputy Director<br/>
Centre for Healthcare Innovation`,
        },
        {
          dImg: "/landing/desktop/speaker/4.png",
          dPopup: "/landing/desktop/popup/4.png",
          mImg: "/landing/mobile/speaker/4.png",
          mPopup: "/landing/mobile/popup/4.png",
          detail: `Consultant<br/>
Centre for Healthcare Innovation`,
        },
        {
          dImg: "/landing/desktop/speaker/5.png",
          dPopup: "/landing/desktop/popup/5.png",
          mImg: "/landing/mobile/speaker/5.png",
          mPopup: "/landing/mobile/popup/5.png",
          detail: `Principal<br/>
The Dao of Thriving`,
        },
        {
          dImg: "/landing/desktop/speaker/6.png",
          dPopup: "/landing/desktop/popup/6.png",
          mImg: "/landing/mobile/speaker/6.png",
          mPopup: "/landing/mobile/popup/6.png",
          detail: `Director<br/>
Office for Service Transformation
SingHealth`,
        },
        {
          dImg: "/landing/desktop/speaker/7.png",
          dPopup: "/landing/desktop/popup/7.png",
          mImg: "/landing/mobile/speaker/7.png",
          mPopup: "/landing/mobile/popup/7.png",
          detail: `Senior Director<br/>
Temasek Foundation`,
        },
        {
          dImg: "/landing/desktop/speaker/8.png",
          dPopup: "/landing/desktop/popup/8.png",
          mImg: "/landing/mobile/speaker/8.png",
          mPopup: "/landing/mobile/popup/8.png",
          detail: `Head, Dept of Family Medicine<br/>
National University Health System`,
        },
        {
          dImg: "/landing/desktop/speaker/9.png",
          dPopup: "/landing/desktop/popup/9.png",
          mImg: "/landing/mobile/speaker/9.png",
          mPopup: "/landing/mobile/popup/9.png",
          detail: `Chief Executive Officer<br/>
AWWA`,
        },
        {
          dImg: "/landing/desktop/speaker/10.png",
          dPopup: "/landing/desktop/popup/10.png",
          mImg: "/landing/mobile/speaker/10.png",
          mPopup: "/landing/mobile/popup/10.png",
          detail: `Executive Director<br/>
Central Health<br/>
Tan Tock Seng Hospital`,
        },
        {
          dImg: "/landing/desktop/speaker/11.png",
          dPopup: "/landing/desktop/popup/11.png",
          mImg: "/landing/mobile/speaker/11.png",
          mPopup: "/landing/mobile/popup/11.png",
          detail: `Vice President (Philips SG) <br/>
Philips Design  `,
        },
        {
          dImg: "/landing/desktop/speaker/12.png",
          dPopup: "/landing/desktop/popup/12.png",
          mImg: "/landing/mobile/speaker/12.png",
          mPopup: "/landing/mobile/popup/12.png",
          detail: `Director<br/>
School of X<br/>
DesignSingapore Council`,
        },
      ],
    };
  },
  mounted() {
    let self = this;
    this.tick();
    this.timer = setInterval(this.tick.bind(this), 1000);
    // $("#videoModal").on("hidden.bs.modal", function (event) {
    //   console.log("modal close");
    //   this.videoSrc = "";
    // });

    $("#videoModal").on("shown.bs.modal", function (e) {
      console.log("here", self.videoSrc);
      self.hide_video = false;
      setTimeout(() => {
        $("#video").attr("src", self.videoSrc);
      }, 500);
    });
    $("#videoModal").on("hide.bs.modal", function (e) {
      self.hide_video = true;
      $("#video").attr("src", self.videoSrc);
    });

    const prevButton = document.querySelector(".prev");
    const nextButton = document.querySelector(".next");
    const buttonWrapper = document.querySelector(".button-wrapper");

    prevButton.addEventListener("click", () => {
      if (self.type == "desktop") {
        if (self.current > 0) {
          let temp = self.current;
          self.current--;
          if (temp == 1) {
            self.openLink(self.videos[2022], 0);
          } else if (temp == 2) {
            self.openLink(self.videos[2021], 1);
          } else if (temp == 3) {
            self.openLink(self.videos[2020], 2);
          }
        }
      }
      buttonWrapper.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    });

    nextButton.addEventListener("click", () => {
      if (self.type == "desktop") {
        if (self.current < 3) {
          let tempN = self.current;

          self.current++;
          if (tempN == 0) {
            self.openLink(self.videos[2021], 1);
          } else if (tempN == 1) {
            self.openLink(self.videos[2020], 2);
          } else if (tempN == 2) {
            self.openModal(self.videos[2019], 3);
          }
        }
      }
      buttonWrapper.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    });
  },

  destroy() {
    clearInterval(this.timer);
  },
  created() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    openSpeakerModal(link) {
      this.currentSpeakerImage = link;
      $("#speakerModal").modal("show");
    },
    openModal(link, index) {
      this.current = index;
      this.videoSrc = link;
      $("#videoModal").modal("show");
    },
    openLink(link, index) {
      this.current = index;
      window.open(link, "_blank");
    },
    onResize() {
      let mq = window.matchMedia("(max-width: 570px)");
      if (mq.matches) {
        this.type = "mobile";
      } else {
        this.type = "desktop";
      }
    },
    updateRemaining(distance) {
      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    tick() {
      const currentTime = new Date();
      const distance = Math.max(this.endDate - currentTime, 0);
      this.updateRemaining(distance);

      if (distance === 0) {
        clearInterval(this.timer);
        this.days = 0;
        this.hours = 0;
        this.minutes = 0;
        this.seconds = 0;
        this.isEnded = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.landing {
  // .hero {
  //   min-height: 100vh;
  //   position: relative;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: flex-end;
  //   align-items: center;
  //   .hero-video {
  //     z-index: -1;
  //     position: absolute;
  //     right: 0;
  //     bottom: 0;
  //     min-width: 100%;
  //     min-height: 100%;
  //   }
  // }
  .join-event {
    // background: #393333;
    box-shadow: 0px -6px 13px 0px rgb(122, 23, 176);
    background-image: linear-gradient(
      135deg,
      rgb(127, 41, 254),
      rgb(217, 65, 103)
    );
    color: #fff;
    h2,
    h3 h4,
    p {
      font-family: "Spaced";
    }
    .line {
      border-left: 1px solid #fff;
      height: 80%;
    }
  }
  .prev-conf {
    .button-container {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      scroll-behavior: smooth;
      width: 90%;
      overflow: hidden;
      height: 100px;
      padding: 0px 25px;

      button {
        position: relative;
        z-index: 1;
        padding: 10px;
        text-align: center;
        min-width: 150px;
        background-color: rgb(32, 7, 100);
        color: rgb(116, 113, 177);
        border: none;
        border-radius: 5px;
        font-size: 16px;
        cursor: pointer;
        margin: 0 24px;
      }
      button:hover {
        background-color: rgb(217, 65, 103);
        color: #fff;
      }
      .button-active {
        transform: scale(1.3);
        background-color: rgb(217, 65, 103);
        color: #fff;
      }
    }

    .line {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      height: 2px;
      z-index: 0;
      background-color: #ccc;
      transform: translateY(-50%);
    }

    .button-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .carousel-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .carousel-button {
      background-color: #ccc;
      color: #fff;
      border: none;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      z-index: 1;

      font-size: 16px;
      cursor: pointer;
      padding: 10px 10px;
    }

    .carousel-button:hover {
      background-color: rgb(217, 65, 103);
    }
  }
  .about {
    background-color: rgb(105, 31, 220);
    .bg-rectangle {
      background-image: linear-gradient(
        to right,
        rgb(127, 41, 254),
        rgb(217, 65, 103)
      );
      padding: 6px 4px;
      p {
        font-weight: bold;
        font-size: 1.2rem;
      }
    }
  }


  .contact {
    background-image: linear-gradient(
      to right,
      rgb(127, 41, 254),
      rgb(217, 65, 103)
    );
    color: #fff;
    a {
      text-decoration: none;
      color: #fff;
    }
    .btn-mail {
      background: rgb(55, 113, 180);
      color: #fff;
      font-family: "Nexa-Light";
      font-size: 1.2rem;
    }
    .mobile-contact {
      h3 {
        font-size: 0.9rem;
      }
      .btn-mail {
        font-size: 0.4rem;
      }
      a {
        font-size: 0.7rem;
      }
    }
  }
  .socials {
    background: rgb(32, 9, 101);
    p {
      font-size: 0.8rem;
    }
  }
  .socials-mobile {
    background: rgb(32, 9, 101);
    p {
      font-size: 0.6rem;
    }
  }

  .close {
    position: absolute;
    right: -30px;
    top: 0;
    z-index: 999;
    font-size: 2rem;
    font-weight: normal;
    color: #fff;
    opacity: 1;
  }
  @media only screen and (max-width: 600px) {
    .close {
      position: absolute;
      right: 20px;
      top: 0px;
      z-index: 999;
      font-size: 2rem;
      font-weight: normal;
      color: #000;
      -webkit-text-stroke: 1px black;
      -webkit-text-fill-color: white;
    }
  }
}
</style>
